@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Regular"),
    url("/fonts/Satoshi-Regular.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Italic"),
    url("/fonts/Satoshi-Italic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Light";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Light"),
    url("/fonts/Satoshi-Light.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Light Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Light Italic"),
    url("/fonts/Satoshi-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Medium";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Medium"),
    url("/fonts/Satoshi-Medium.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Medium Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Medium Italic"),
    url("/fonts/Satoshi-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Bold";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Bold"),
    url("/fonts/Satoshi-Bold.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Bold Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Bold Italic"),
    url("/fonts/Satoshi-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Black";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Black"),
    url("/fonts/Satoshi-Black.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Black Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Satoshi Black Italic"),
    url("/fonts/Satoshi-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "Switzer";
  src: url("/fonts/Switzer-Extrabold.otf") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 100% 100%;
    --foreground: 30 2% 13%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 240 100% 7%;
    --input: 40 1% 41%;

    --primary: 162 12% 16%;
    --primary-foreground: 38, 44%, 96%;

    --secondary: 0 0% 88%;
    --secondary-foreground: 162 12% 16%;

    --accent: 0 0% 97%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 90% 74%;
    --destructive-foreground: 0 100% 100%;

    --title: 158 17% 12%;

    --ring: 215 20.2% 65.1%;

    --radius: 16px;
  }

  /* .dark { 
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --title: 158 17% 12%;

    --ring: 217.2 32.6% 17.5%;
  } */
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-family:
      Satoshi,
      -apple-system,
      BlinkMacSystemFont,
      Roboto,
      Helvetica Neue,
      sans-serif;
    color: var(--foreground);
  }

  .font-feature-settings-liga-off {
    font-feature-settings: "liga" off;
  }

  a {
    @apply text-interface-link;
  }

  .h1-lp {
    font-family: EB Garamond;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    @apply text-emerald-900;
  }

  .h2-lp {
    font-family: EB Garamond;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    @apply text-interface-black;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  /* 1st heading level */
  h1 {
    font-family: EB Garamond;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    @apply text-emerald-900;
  }

  /* 2nd heading level */
  h2,
  .h2 {
    font-family: EB Garamond;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    @apply text-interface-black;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  /* 3rd heading level, also called sub-headline */
  h3 {
    font-family: EB Garamond;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    @apply text-interface-black;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  /* .h2 {
    font-family: EB Garamond;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 126%;
    @apply text-emerald-black;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  } */

  /* .h2-bold {
    font-family: EB Garamond;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    @apply text-emerald-black;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  } */

  .subtitle-1 {
    font-family: EB Garamond;
    font-size: 24px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: left;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  h4 {
    font-family: Satoshi Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 33.6px */
    letter-spacing: 0.48px;
    @apply text-emerald-black;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .h4 {
    font-family: Satoshi Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 33.6px */
    letter-spacing: 0.48px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .h4-light {
    font-family: Satoshi;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 33.6px */
    letter-spacing: 0.48px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .h4-mobile {
    font-family: Satoshi Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
    letter-spacing: 0.4px;
  }

  h5 {
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.4px;
    @apply text-emerald-black;
  }

  .h5 {
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.4px;
    @apply text-emerald-black;
  }

  .h5-bold {
    font-family: Satoshi Bold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.4px;
    @apply text-emerald-black;
  }

  h6 {
    font-family: EB Garamond;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    @apply text-emerald-black;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .h6 {
    font-family: EB Garamond;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    @apply text-emerald-black;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .h6-mobile {
    font-family: EB Garamond;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-feature-settings:
      "cv11" on,
      "liga" off;
  }

  .h7 {
    font-family: EB Garamond;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    @apply text-emerald-black;
  }

  .p1 {
    font-family: Satoshi;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 33px */
    letter-spacing: 0.55px;
  }

  .p1-mobile {
    font-family: Satoshi;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: 0.45px;
  }

  .p1-medium {
    font-family: Satoshi Medium;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 33px */
    letter-spacing: 0.55px;
  }

  .p1-medium-mobile {
    font-family: Satoshi Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 27px */
    letter-spacing: 0.45px;
  }

  .p1-bold {
    font-family: Satoshi Bold;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 33px */
    letter-spacing: 0.55px;
  }

  .p1-semi-bold {
    font-family: Satoshi bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 33px */
    letter-spacing: 0.55px;
  }

  .p1-bold-mobile {
    font-family: Satoshi Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 27px */
    letter-spacing: 0.45px;
  }

  .p1-reduce {
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.5px;
  }

  .p2 {
    font-family: Satoshi;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.45px;
    @apply text-interface-black;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .p2-mobile {
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.4px;
  }

  .p2-bold {
    font-family: Satoshi Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.45px;
  }

  .p2-bold-mobile {
    font-family: Satoshi Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.45px;
  }

  .p2-semi-bold {
    font-family: Satoshi Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 27px */
    letter-spacing: 0.45px;
  }

  .p2-medium {
    font-family: Satoshi Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 27px */
    letter-spacing: 0.45px;
  }

  .p2-medium-light {
    font-family: Satoshi;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 27px */
    letter-spacing: 0.45px;
  }

  .p2-medium-mobile {
    font-family: Satoshi Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.4px;
  }

  .p3 {
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.4px;
  }

  .p3-mobile {
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.35px;
  }

  .p3-medium {
    font-family: Satoshi Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.4px;
  }

  .p3-link {
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: 0.4px;
  }

  .p3-medium-mobile {
    font-family: Satoshi Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.35px;
  }

  .p3-bold {
    font-family: Satoshi Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.4px;
  }

  .p3-bold-mobile {
    font-family: Satoshi Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.35px;
  }

  .p4 {
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.35px;
  }

  .p4-mobile {
    font-family: Satoshi;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: left;
  }

  .p4-medium {
    font-family: Satoshi Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.35px;
  }

  .p4-medium-mobile {
    font-family: Satoshi Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
    letter-spacing: 0.3px;
  }

  .p4-bold-mobile {
    font-family: Satoshi Bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 18px */
    letter-spacing: 0.3px;
  }

  .p5-medium {
    font-family: Satoshi Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
    letter-spacing: 0.24px;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }

  .p5 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
    letter-spacing: 0.24px;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }

  .l4 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    letter-spacing: 0.24px;
  }

  /* Desktop/Labels/L3 */
  .l3 {
    font-family: Satoshi Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
    /* 21.7px */
    letter-spacing: 0.42px;
  }

  .b1 {
    font-family: Satoshi;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  .b3 {
    font-family: Satoshi Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .b5 {
    font-family: Satoshi Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.28px;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  @apply text-coffee-500;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-size: 18px;
}

.tiptap,
.prose-lg {
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror.prose {
  max-width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.skip-link {
  position: absolute;
  top: 16px;
  right: 100%;
  /* moves off screen */
  z-index: 500;
  /* some number that will make sure it covers other elements */
}

.skip-link:focus {
  right: auto;
  @apply bg-interface-white;
  @apply text-emerald-900;
  width: 267px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 16px;
  text-align: center;
}

.bad-script-italic {
  font-family: "Bad Script", cursive;
  font-weight: 400;
  font-style: italic;
  font-size: 48px;
  line-height: 72px;
}

.max-h-available {
  max-height: -webkit-fill-available;
}

.table-mobile thead {
  display: none;

  @media screen and (min-width: 769px) {
    display: table-header-group;
  }
}

.animate-gradient-frame {
  border: solid 4px transparent;
}

.animate-gradient {
  --border-angle: 0turn;
  --main-bg: conic-gradient(
    from var(--border-angle),
    #3e4946,
    #3e4946 5%,
    #3e4946 60%,
    #3e4946 95%
  );

  border: solid 4px transparent;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #3e4946,
    #fdfdfd 99%,
    transparent
  );

  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
}

.thumbnail_active.active {
  border: 1px solid #1e7661;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.main-children > div {
  height: 100%;
}

.p0-bold {
  font-family: Satoshi Bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 33px */
  letter-spacing: 0.55px;
}

.p2-medium-custom {
  font-family: Satoshi;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.45px;
}

.subtitle-2 {
  font-family: EB Garamond;
  font-size: 28px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.font-eb-garamond {
  font-family: EB Garamond;
}

.responsive-spacing {
  @apply mx-auto px-4 pb-14 sm:max-w-screen-sm sm:px-0 md:max-w-screen-md md:pb-148px lg:max-w-screen-lg xl:max-w-screen-benchmark 2xl:max-w-screen-2xl;
}

.responsive-spacing-1st-order {
  @apply responsive-spacing pt-14 md:pt-148px;
}

.stated-icon {
  @apply rounded-full hover:bg-coffee-50 focus:bg-coffee-100 focus-visible:bg-coffee-100 active:bg-coffee-100 disabled:bg-coffee-100;
}
